import Swiper from "swiper";
import on from "./_general_methods.js";


export const mainSwiperSliderCreate = function (){
  let slideElement = "#main-swiper-container";
  var mainSliderOptions = {
    //added
    /*
    passiveListeners: true,
    touchStartPreventDefault: false,
    touchMoveStopPropagation: false,
    */
    /*
    observer: true, 
    observeParents: true,
    observeSlideChildren: true,
    */
    roundLengths: true,
    init: true,
    loop: false,
    //loopedSlides: 10,
    speed:800,
    slidesPerView: 'auto', // or 'auto'
    spaceBetween: 20,
    //centerInsufficientSlides: true,
    centeredSlides : true,
    threshold: 0,
    //centeredSlidesBounds: true,
    /*
    PreventClicks: true,
    PreventClicksPropagation: true,
    */
    //クリックで画像をスライド
    //slideToClickedSlide: true,
    effect : 'fade', // 'cube', 'fade', 'coverflow',
    /*
    coverflowEffect: {
      rotate: 50, // Slide rotate in degrees
      stretch: 0, // Stretch space between slides (in px)
      depth: 100, // Depth offset in px (slides translate in Z axis)
      modifier: 1, // Effect multipler
      slideShadows : true, // Enables slides shadows
    },
    */
    grabCursor: false,
    //parallax: true,
    pagination: {
      el: '.main-swiper-pagination',
      type: 'bullets',
    },
    breakpoints: {
      768: {
        threshold: 20,
        spaceBetween: 0,
        slidesPerView: 'auto',
        centeredSlides : true,
        /*
        coverflowEffect: {
          rotate: 25, // Slide rotate in degrees
          stretch: 0, // Stretch space between slides (in px)
          depth: 100, // Depth offset in px (slides translate in Z axis)
          modifier: 1, // Effect multipler
          slideShadows : true, // Enables slides shadows
        },
        */
      }
    },
    // Events
    on: {
      imagesReady: function(){
        this.el.classList.remove('loading');
      },
    }
  };
 const mainSlider = new Swiper( slideElement, mainSliderOptions );
 return mainSlider;
}


export const aboutUsSwiperCreate = () => {
  (() => {

  })();

}