import { fadeIn, on, fadeOut } from "../modules/_general_methods.js";


export const removeHash = () => {


    const removeHashOnHashChanged = () => {
        let scrollV, scrollH, loc = window.location;
        if ("pushState" in history)
            history.pushState("", document.title, loc.pathname + loc.search);
        else {
            // Prevent scrolling by storing the page's current scroll offset
            scrollV = document.body.scrollTop;
            scrollH = document.body.scrollLeft;

            loc.hash = "";

            // Restore the scroll offset, should be flicker free
            document.body.scrollTop = scrollV;
            document.body.scrollLeft = scrollH;
        }
    }
    const removeHashOnloaded = () => {
        let hashString = location.hash.substr(1); // remove '#'
        history.replaceState('', document.title, window.location.pathname);
    }

    window.addEventListener("hashchange", removeHashOnHashChanged, false);

    window.addEventListener("loaded", removeHashOnloaded, true);

}


export const fadeFloatButtons = () => {

    window.addEventListener("load", () => {
        let sideBtn = document.getElementById("sideBtn");
        //sideBtn.style.opacity = 0;
        const sideBtnFadeInFadeOutFunc = () => {
            let docHeight = document.body.clientHeight;
            let dispHeight = window.innerHeight;
            let footerHeight = document.getElementById("Footer").clientHeight;
            let currentScrollHeight = window.pageYOffset;

            if (currentScrollHeight < 200) {
                sideBtn.style.opacity = "0";
            } else if (currentScrollHeight > docHeight - dispHeight - footerHeight) {
                sideBtn.style.opacity = "0";
            } else {
                sideBtn.style.opacity = "1.0";
            }
        }
        sideBtnFadeInFadeOutFunc();

        window.addEventListener("scroll", sideBtnFadeInFadeOutFunc, false);

    }, false);

}

export const fadeInFloatBtn = () => {
    const breakPoint = 981;
    const contactBtnLinkFunc = () => {
        const contactBtnLink = document.querySelector("#contactBtnLink");
        const telNumber = "072-462-0550";
        const hrefTel = `tel:${telNumber}`;
        if (window.innerWidth >= breakPoint) {
            contactBtnLink.href = "javascript:void(0)";
            contactBtnLink.removeAttribute("href");
        } else {
            contactBtnLink.setAttribute("href", `${telNumber}`);
            contactBtnLink.href = hrefTel;
        }
    }
    window.addEventListener("load", contactBtnLinkFunc);
    window.addEventListener("resize", contactBtnLinkFunc);
}




/* export const bannerFadeOut = () => {
  
  (function () {
    window.addEventListener( "scroll" , () => {
      let check = window.pageYOffset ;
      let docHeight = document.body.clientHeight;
      let dispHeight = window.innerHeight;
      let footerHeight = document.querySelector("#Footer").clientHeight;
      let target = document.getElementById("sideBtn");
      let headerHeight = 200;
      if( (check < docHeight-dispHeight-footerHeight) ){
        target.style.opacity = "0";
        console.log(`check: ${check}`);
        console.log(false);
        console.log(`height: ${docHeight-dispHeight-footerHeight}`);

      }else if( (check > headerHeight) ){
        target.style.opacity = "1.0";
        console.log(`check: ${check}`);
        console.log(true);
        console.log(`height: ${docHeight-dispHeight-footerHeight}`);
      }else{
        target.style.opacity = "1.0";
        console.log(`check: ${check}`);
        console.log(true);
        console.log(`height: ${docHeight-dispHeight-footerHeight}`);
      }

    })
})()
} */

/* 
export const smoothScrollHeightAdjustment = () => {

  window.on( "resize load" , () => {
    let y = window.pageYOffset;
    let headerHeight = document.querySelector(".header").clientHeight;
    
    if( y < headerHeight ){
      var headerNaviHeight = document.querySelector(".HeaderMenu--hidden").clientHeight;
    }else{
      var headerNaviHeight = document.querySelector(".HeaderMenu").clientHeight;
    }
    let wholeHeight = headerHeight + headerNaviHeight;
    let anchor = document.querySelectorAll(".anchor");
    anchor.forEach(element => {
      element.style.paddingTop = `${wholeHeight}px`;
      element.style.marginTop = `-${wholeHeight}px`;
      console.log(element.style.paddingTop);
      console.log(element.style.marginTop);
    }); 
  });

} */