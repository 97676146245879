//On Top page 
export const topPage = () => {
  
  const removeTrashesOnTopMedia = () => {
    if( location.pathname === "/" || location.pathname.indexOf("/#") === 0 ){
  /*     document.addEventListener( "load" , () => {
        let target = document.getElementById("Breadcrumb");
        target.style.display = "none";
      }) */
  
      window.addEventListener( "load" ,
        () => {
          let scrollBtn = document.getElementById("scroll-btn");
          scrollBtn.addEventListener( "click" , (e) => {
            const target = e.target;
            if (!target.classList.contains("js-smooth-scroll")) return;
            e.preventDefault();
            const targetId = target.hash;
            document.querySelector(targetId).scrollIntoView({
              behavior: "smooth",
              block: "start"
            });
            
          });
        }
      )
  
  
    }
  
  }
  
}

