import Cookies from 'js-cookie';
import { cookiesSet, cookiesAction } from "../modules/_general_methods.js";



export const medicalInformation = () => {
    if (location.pathname.indexOf("/medical-information/") === 0) {

        const anchorJumpWithCookies = () => {
            let cookie = Cookies.get('Cookie_link');
            let sectionPullDownsLength = document.querySelectorAll(".acd-section").length;
            for (let i = 0; i < sectionPullDownsLength; i++) {
                cookiesAction(`#acd-check0${i}`, 'Cookie_Link', `#main-content0${i}`);
            }
        }
        anchorJumpWithCookies();


        const jumpToAcdAnchor = (button, anchor, e) => {
            e.preventDefault();
            let acdCheck = document.querySelector(anchor)
            acdCheck.nextElementSibling.firstElementChild.checked = true;
            location.hash = anchor;
        }
    }

}