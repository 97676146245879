import { fadeOut, fadeIn } from "../modules/_general_methods.js";

export const forceDeleteLoader = () => {

    let loaderWrapper = document.querySelector('#loader svg')

    function draw() {
        loaderWrapper.classList.add('active');
    }

    function removeActiveClass() {
        loaderWrapper.classList.remove('active');
    }
    setTimeout(draw, 0);
    //window.addEventListener( "load" , removeActiveClass )

    let loader = document.getElementById("loader");
    setTimeout(function() {
        fadeOut(loader, 500, "block");
        removeActiveClass();
    }, 2000);
}