//jQuery modules
//import $ from "../../../../../../node_modules/jquery";
import $ from "jquery";
import { infiniteSlide } from "./jquery/infiniteslide.js";
import { jqueryPause } from "./jquery/jquery.pause.js";
import { fixedHeader, slider } from "./jquery/main.js";
import { drawer } from "./jquery/drawer.js";
import Swiper from "swiper";

//general methods
import { cookiesAction, fadeIn, fadeOut } from "./modules/_general_methods.js";

//npm libraries
import Cookies from 'js-cookie';

//Original Method
import { SugoudeMethods } from "./modules/_sugoudemethods.js";


//mainSlider
import { aboutUsSwiperCreate, mainSwiperSliderCreate } from "./modules/_swiperSlider.js";
import { insertJapYear } from "./pages/_archive-pages.js";

//aboutUsSwiperSlider


//_top-page.js
import { removeTrashesOnTopMedia, topPage } from "./pages/_top-page.js";
import { forceDeleteLoader } from "./modules/_loader.js";
import { drawerToggle, fadeFloatButtons, fadeInFloatBtn, removeHash } from "./pages/_all-pages.js";
import { anchorJumpWithCookies, medicalInformation } from "./pages/_medical-information-page.js";

(() => {

    //FixedHeader
    fixedHeader();
    //FloatButtons
    fadeFloatButtons();


    //SwiperOnMainImage
    mainSwiperSliderCreate();


    //DrawerMenu

    drawer();

    //アーカイブ年を追加する
    insertJapYear();


    //remove hash
    removeHash();


    //scripts on each pages
    topPage();
    medicalInformation();

    //floatBtnFunc
    fadeInFloatBtn();

    //Loader2000msで強制的に削除
    //forceDeleteLoader();

})();