//On archive-event_schedule.php
export const insertJapYear = () => {
  (() => {
    if( document.querySelectorAll(".archive_wrapper > a") ){
      let targets = document.querySelectorAll(".archive_wrapper > a");
      targets.forEach(target => {
        let yearText = target.children;
        target.insertAdjacentText( "beforeend" , "年" )
      });
    }
  })();
}
