import $ from "jquery";

export const fixedHeader = function() {
    //ヘッダー固定
    let headerFixed = {
        classFixed: "fixed",
        headerObj: '.header',
        wrapperObj: 'body',
        init: function() {
            $(window).scroll(function() {
                //if(!$(".drawer-open")[0]){
                let headerElmHeight = document.querySelector("header").clientHeight;
                if (document.querySelector("#Breadcrumb")) {
                    var breadcrumbElmHeight = document.querySelector("#Breadcrumb").clientHeight;
                }

                if (location.pathname === "/" && window.innerWidth >= 768) {
                    var heroImageHeight = document.getElementById("heroWrap").clientHeight;
                    var topMenuHeight = document.getElementById("TopMenu").clientHeight;
                    var headerWholeHeight = headerElmHeight + breadcrumbElmHeight + heroImageHeight + topMenuHeight;
                } else {
                    var headerWholeHeight = headerElmHeight + breadcrumbElmHeight;
                }
                var scrollTop = $(document).scrollTop();
                if (scrollTop > headerWholeHeight)
                    headerFixed.addClass();
                else
                    headerFixed.removeClass();
                //}
            }.bind(this));
        },
        addClass: function() {
            this.AddHeaderHeight();
            $(this.headerObj).addClass(this.classFixed);
        },
        removeClass: function() {
            this.RemoveHeaderHeight();
            $(this.headerObj).removeClass(this.classFixed);
        },
        AddHeaderHeight: function() {
            $(this.wrapperObj).css({ "padding-top": $(this.headerObj).height() });
        },
        RemoveHeaderHeight: function() {
            $(this.wrapperObj).css({ "padding-top": 0 });
        }
    };

    let PageTop = {
        btn: ".pagetop",
        sidebtn: ".sidebtn",
        init: function() {
            this.click();
            this.animate();
        },
        click: function() {
            $(this.btn).on("click", function() {
                $('html, body').animate({ scrollTop: 0 }, 500);
                return false;
            });
        },

        animate: function() {
            /*  $(this.sidebtn).hide();
             var sidebtn = this.sidebtn;
             let docHeight = $(document).height();
             let dispHeight = $(window).height();
             let footerHeight = $("#Footer").height();
             $(window).on( "scroll" ,function(){
               if($(this).scrollTop() < 200){
                 $(sidebtn).fadeOut();
               }else if ($(this).scrollTop() > docHeight - dispHeight - footerHeight){
                 $(sidebtn).fadeOut();
               }
               else{
                 $(sidebtn).fadeIn();
               }
             }); */
        }
    };
    (function() {
        $(".drawer-toggle").click(function() {
            $(".DrawerMenu").fadeToggle();
            $("body").toggleClass("drawer-open");
        });
        PageTop.init();
        headerFixed.init();
    })();
}